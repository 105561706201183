.page-not-found header {
	min-height: 50px;
  }
  
  .page-not-found .head-text {
	text-transform: uppercase;
	width: 200px;
	height: auto;
	padding: 20px;
  }
  
  .page-not-found .head-text, .main-wrapper {
	width: 80%;
	margin: auto;
  }
  
  .page-not-found .main-wrapper {
	display: flex;
  }
  
  .page-not-found .scarecrow-img img {
	width: 90%;
	height: auto;
  }
  
  .page-not-found .error-text {
	width: 70%;
  }
  
  .page-not-found .error-text h2 {
	width: 80%;
	font-size: 3.75rem;
	letter-spacing: 0.5px;
	font-weight: normal;
  }
  
  .page-not-found .error-text p {
	width: 50%;
	padding: 5px;
  }
  
  .page-not-found button {
	cursor: pointer;
	width: auto;
	padding: 15px;
	border: 1px solid #333;
	border-radius: 3px;
	color: white;
	background-color: #333;
	text-transform: uppercase;
	margin-top: 15px;
  }
  
  .page-not-found footer {
	padding: 15px;
	text-align: center;min-height: 50px;
	margin-top: auto;
  }
  
  .page-not-found .fa-copyright {
	font-weight: lighter;
  }
  
  /* star mobile-first media queries */
  
  /* Small devices (landscape phones, 576px and up) */
  
  
  /* Medium devices (tablets, 768px and up) */
  @media (max-width: 991.9px) { 
	.page-not-found .main-wrapper {
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  align-items: center;
	}
  
	.page-not-found .error-text h2 {
	  width: 100%;
	  font-size: 3rem;
	  line-height: 100%;
	  padding-top: 15px;
	}
  
	.page-not-found .error-text p {
	  width: 100%;
	  font-size: 0.8rem;
	  line-height: 150%;
	  padding-top: 15px;
	}
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 
	.page-not-found .error-text h2 {
	  width: 100%;
	  line-height: 100%;
	  padding-top: 15px;
	}
  
	.page-not-found .error-text p {
	  width: 100%;
	  line-height: 150%;
	  padding-top: 15px;
	}
  }
  